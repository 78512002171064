import template from './download.html';
import header from '../header.html';

export default angular.module('eventix.order.download', [])
    .config(function($stateProvider, GoogleTagManagerProvider) {
        $stateProvider.state('eventix.download', {
            url: '/download/:guid',
            views: {
                main: {
                    controller: 'OrderDownloadController',
                    controllerAs: 'vm',
                    templateUrl: template
                },
                header: {
                    controller: 'OrderStatusHeaderController',
                    controllerAs: 'vm',
                    templateUrl: header
                }
            },
            resolve: /*@ngInject*/{
                order: function(Order, $transition$) {
                    return Order.getPublic($transition$.params().guid).then(order => {
                        // Load Tag as early as possisble
                        if(order.shop.google_tag)
                            GoogleTagManagerProvider.addContainer(order.shop.google_tag);
                        return order;
                    });
                }
            }
        });
    })
    .controller('OrderDownloadController', function($state, $scope, $q, order, $timeout, UIMessages, $window, VisitorData) {
        const vm = this;
        vm.messages = UIMessages;
        vm.order = order;
        vm.shopLink = SHOP_ROOT + '/' + order.shop_id;
        vm.shopUrl = SHOP_ROOT + '/' + order.shop_id;
        vm.shop = order.shop;
        vm.isMobile = /mobile/i.test(_.get(VisitorData, 'userAgent.device.type', ''));
        vm.isApple = /mac|ios/i.test(_.get(VisitorData, 'userAgent.os.name', ''));
        vm.downloadReady = false;
        vm.supportValues = {
            email: $scope.branding.email || 'tickets@eventix.nl'
        };
        vm.cancel = (e) => e.preventDefault() && e.stopPropagation();
        vm.currency = _.get(vm.payment, 'currency', DEFAULT_CURRENCY);

        (function poll() {
            vm.order.ticketDownloadReady()
                .then(order => {
                    vm.order = order;
                    vm.downloadReady = order.download_ready;
                })
                .catch(error => {
                    if (error.message === 'processing')
                        $timeout(poll, 5000);
                    else if(angular.isString(error.message))
                        UIMessages.push(error.message);
                    else if(angular.isString(error))
                        UIMessages.push(error);
                    else
                        UIMessages.push('common.notice.error');
                });
        })();
    }).filter('isAutomaticProduct', function() {
        return function(input) {
            if(!_.isObject(input))
                return false;
            return /^(Access for|Product for)/.test(_.get(input, 'name', ''));
        };
    }).name;
